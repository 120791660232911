<template>
	<div>
		<base-table :items="orders" :headers="ordersHeaders" table-top paginate>
			<template #cell(actions)="data">
				<b-button variant="outline-secondary" class="cursor-pointer" size="sm" @click="openDetails(data.item)">
					<span class="align-middle">Items </span>
				</b-button>
			</template>

			<template #cell(type)="{ value }">
				<span>{{ value === 1 ? 'عروض اسعار فعلية' : 'عروض اسعار شفوية' }}</span>
			</template>
		</base-table>

		<b-modal v-model="showDetails" title="Order details" size="lg" hide-footer centered>
			<Details :current-order="currentOrder" />
		</b-modal>
	</div>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import Details from './Details.vue';

export default {
	name: 'Purchases',

	components: { BaseTable, BModal, BButton, Details },

	data: () => ({
		orders: [],

		ordersHeaders: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'date', sortable: true },
			{ key: 'place', sortable: true },
			{ key: 'type', sortable: true },
			{ key: 'suppler', sortable: true },
			{ key: 'closing_hour', sortable: true },
			{ key: 'actions' }
		],

		showDetails: false,
		currentOrder: null
	}),

	async created() {
		this.orders = await store.dispatch('purchases/getAcceptedOrders', this.$route.params.subId);
	},

	methods: {
		openDetails(order) {
			this.currentOrder = order;
			this.showDetails = true;
		}
	}
};
</script>

<style lang="scss" scoped></style>
