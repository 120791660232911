<template>
	<div>
		<base-table :items="invoices" :headers="invoicesHeaders" table-top paginate>
			<template #cell(actions)="data">
				<b-button v-b-modal.receive-invoice variant="outline-secondary" class="cursor-pointer" size="sm" @click="receiveInvoice(data.item)">
					<span class="align-middle">Receive </span>
				</b-button>
			</template>
		</base-table>

		<ReceiveInvoice v-if="currentInvoice" :invoice="currentInvoice" />
	</div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import ReceiveInvoice from './ReceiveInvoice.vue';

export default {
	name: 'Purchases',

	components: { BaseTable, BButton, ReceiveInvoice },

	data: () => ({
		invoices: [],

		invoicesHeaders: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'supplier', sortable: true },
			{ key: 'item', sortable: true },
			{ key: 'qunatity', sortable: true },
			{ key: 'price', sortable: true },
			{ key: 'total', sortable: true },
			{ key: 'phone', sortable: true },
			{ key: 'actions' },
		],

		currentInvoice: null,
	}),

	async created() {
		this.invoices = await store.dispatch('purchases/getInvoices', this.$route.params.id);
	},

	methods: {
		receiveInvoice(invoice) {
			this.currentInvoice = invoice;
		},
	},
};
</script>

<style lang="scss" scoped></style>
