<template>
	<div>
		<div v-if="currentOrder.type === 'لا'" class="">
			<p class="mb-50 font-weight-bold">Reasons for not being able to get actual quotes</p>
			<p class="p-75 border rounded" style="background-color: #f9f9f9">{{ currentOrder.reasons }}</p>
		</div>
		<base-table :headers="headers" :items="currentOrder.items" table-top paginate class="shadow-none border-secondary">
			<template #cell(total)="{ item }">
				<span>{{ item.quantity * item.price }}</span>
			</template>
		</base-table>
	</div>
</template>

<script>
import BaseTable from '@/components/BaseTable.vue';

export default {
	name: 'OrderDetails',

	components: { BaseTable },

	props: {
		currentOrder: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'name', sortable: true },
			{ key: 'quantity', label: 'quantity', sortable: true },
			{ key: 'unit', sortable: true },
			{ key: 'price', label: 'price', sortable: true },
			{ key: 'total', sortable: true }
		]
	})
};
</script>

<style lang="scss" scoped></style>
