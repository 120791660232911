<template>
	<div>
		<b-modal
			id="receive-invoice"
			ref="receiveInvoice"
			title="Receive invoice"
			ok-title="Save"
			centered
			cancel-variant="outline-secondary"
			@hidden="resetModal"
			@ok="handleOk"
		>
			<validation-observer ref="formRules">
				<b-form>
					<b-form-group label="Received quantity" label-for="name">
						<validation-provider #default="{ errors }" name="Received quantity" rules="required">
							<b-form-input
								id="name"
								v-model="formData.received_quantity"
								:state="errors.length > 0 ? false : null"
								placeholder="Received quantity"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group class="mt-2" label="If the received quantity not equal to the ordered quantity, say why?" label-for="description">
						<b-form-textarea id="description" v-model="formData.gap_reason" type="text" />
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormTextarea, BFormGroup, BForm } from 'bootstrap-vue';
import { required } from '@validations';

export default {
	name: 'ReceiveInvoice',

	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormTextarea,
		BFormGroup,
		BForm,
	},

	props: {
		invoice: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		required,
		formData: {
			received_quantity: '',
			gap_reason: '',
		},
	}),

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			// Hide the modal manually
			this.$nextTick(() => {
				this.$refs.receiveInvoice.toggle();
			});

			this.$emit('done');
		},
	},
};
</script>

<style lang="scss" scoped></style>
