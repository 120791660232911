<template>
	<b-card no-body class="mb-0">
		<div v-if="tableTop" class="m-2">
			<b-row>
				<b-col cols="12" :md="noSearch ? 12 : 8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
					<!-- server excel button -->
					<excel-download-btn v-if="serverExcelExportUrl" class="mr-1" :url="serverExcelExportUrl" :file-name="excelFileName" />

					<!-- local excel button -->
					<loading-btn
						v-else-if="excelFileName"
						class="mr-1"
						variant="primary"
						:loading="exportToExcelLoading"
						@click="exportExcel"
					>
						Export Excel
					</loading-btn>

					<label>Entries</label>
					<v-select
						v-model="perPage"
						:options="perPageOptions"
						:clearable="false"
						class="per-page-selector d-inline-block ml-50 mr-1"
					/>
					<slot name="action"></slot>
				</b-col>

				<b-col v-if="!noSearch" cols="12" md="4">
					<div class="d-flex align-items-center justify-content-end">
						<b-form-input v-model="searchQuery" class="d-inline-block " placeholder="Search..." />
					</div>
				</b-col>
			</b-row>
		</div>

		<b-table
			:id="uniqTableID"
			ref="refItemsTable"
			class="position-relative"
			v-bind="$attrs"
			:items="getItems"
			responsive
			:fields="tableHeaders"
			:primary-key="rowKey"
			:sort-by.sync="sortCol"
			:per-page="perPage"
			:current-page="currentPage"
			show-empty
			empty-text="No matching records found"
			:sort-desc.sync="isDesc"
		>
			<template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
				<slot :name="slot" v-bind="props" />
			</template>

			<template v-if="hasSelect" #cell(#)="data">
				<b-form-checkbox v-model="selected" :value="data.item.id" class="px-0"></b-form-checkbox>
			</template>
			<template v-if="hasSelect" #head(#)>
				<b-form-checkbox v-model="allSelected" class="px-0" @change="selectAll"></b-form-checkbox>
			</template>

			<template #table-busy>
				<div class="text-center text-primary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<span class="d-block mt-1">Loading...</span>
				</div>
			</template>
		</b-table>

		<div v-if="paginate" class="mx-2 mb-2">
			<b-row>
				<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
					<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
				</b-col>
				<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
					<b-pagination
						v-model="currentPage"
						:total-rows="totalItems"
						:per-page="perPage"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
		</div>
	</b-card>
</template>

<script>
import vSelect from 'vue-select';
import { BCard, BTable, BRow, BCol, BPagination, BSpinner, BFormInput, BFormCheckbox } from 'bootstrap-vue';
import * as xlsx from 'xlsx';
import LoadingBtn from '@/components/LoadingBtn.vue';
import ExcelDownloadBtn from '@/components/ExcelDownloadBtn.vue';

const generateUniqTableID = () => `id-${Math.round(Math.random() * 1e8)}-${new Date().getTime()}`;

export default {
	name: 'BaseTable',

	components: { LoadingBtn, BCard, BTable, BRow, BCol, BPagination, BSpinner, BFormInput, BFormCheckbox, vSelect, ExcelDownloadBtn },

	inheritAttrs: false,

	props: {
		headers: {
			type: Array,
			default: undefined,
		},

		items: {
			type: [Array, Function],
			default: () => [],
		},

		paginate: {
			type: Boolean,
		},

		tableTop: {
			type: Boolean,
		},

		sortBy: {
			type: String,
			default: 'id',
		},

		rowKey: {
			type: String,
			default: 'id',
		},

		asc: {
			type: Boolean,
		},

		hasSelect: {
			type: Boolean,
		},

		noSearch: {
			type: Boolean,
		},

		excelFileName: { type: String },
		serverExcelExportUrl: { type: String }, // server or local
	},

	data: () => ({
		uniqTableID: generateUniqTableID(),
		localItems: [],
		totalItems: 0,
		currentPage: 1,
		perPage: 25,
		perPageOptions: [10, 25, 50, 100],

		searchQuery: '',

		sortCol: 'id',
		isDesc: false,

		allSelected: false,
		selected: [],
		exportToExcelLoading: false,
	}),

	computed: {
		dataMeta() {
			const localItemsCount = this.$refs.refItemsTable ? this.$refs.refItemsTable.localItems.length : 0;
			return {
				from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
				to: this.perPage * (this.currentPage - 1) + localItemsCount,
				of: this.totalItems,
			};
		},

		tableHeaders() {
			return this.hasSelect ? [{ key: '#', sortable: false }, ...this.headers] : this.headers;
		},
	},

	watch: {
		searchQuery() {
			this.refresh();
		},
		perPage() {
			this.refresh();
		},
		currentPage() {
			this.refresh();
		},
		items() {
			this.refresh();
		},
		selected: {
			handler(val) {
				if (this.items instanceof Array) {
					this.allSelected = val.length === this.items.length;
				} else {
					this.allSelected = val.length === this.localItems.length;
				}

				this.$emit('selectChange', this.selected);
			},
			deep: true,
		},
	},

	created() {
		this.sortCol = this.sortBy;
		this.isDesc = !this.asc;
	},

	methods: {
		refresh() {
			this.$refs.refItemsTable.refresh();
		},

		async getItems(ctx) {
			// process table data locally
			if (this.items instanceof Array) {
				const data = this.applySearchFilter(this.items);
				this.sortData(data);
				this.totalItems = data.length;
				return this.paginateData(data);
			}

			// server side processing
			const { data, meta, total } = await this.items(ctx, this.searchQuery);

			this.localItems = data;
			this.totalItems = meta ? meta?.total || data.length : total;

			return data;
		},

		applySearchFilter(items) {
			return items.filter((item) => {
				let inSearch = false;

				Object.keys(item).forEach((key) => {
					const value = item[key] || '';
					if (
						value
							.toString()
							.toLowerCase()
							.includes(this.searchQuery.toLowerCase())
					) {
						inSearch = true;
					}
				});

				return inSearch;
			});
		},

		sortData(data) {
			return data.sort((a, b) => {
				if (a[this.sortCol] < b[this.sortCol]) return this.isDesc ? 1 : -1;
				if (a[this.sortCol] > b[this.sortCol]) return this.isDesc ? -1 : 1;

				return 0;
			});
		},

		paginateData(data) {
			const start = this.currentPage === 1 ? this.currentPage - 1 : (this.currentPage - 1) * this.perPage;
			return data.slice(start, start + this.perPage);
		},

		async selectAll(value) {
			this.selected = [];
			if (value) {
				this.selected.push(...this.localItems.map((i) => i.id));
			}
		},

		async exportExcel() {
			this.exportToExcelLoading = true;
			const perPageTemp = this.perPage;
			this.perPage = this.totalItems;

			setTimeout(() => {
				const el = document.getElementById(this.uniqTableID);
				const sheet = xlsx.utils.table_to_book(el, { sheet: 'Sheet JS' });

				xlsx.writeFile(sheet, `${this.excelFileName}.xlsx`);

				this.perPage = perPageTemp;
				this.exportToExcelLoading = false;
			}, 500);
		},
	},
};
</script>

<style lang="scss">
.per-page-selector {
	width: 90px;
}
</style>
