<template>
	<div>
		<div v-if="currentOrder.type === 'لا'" class="">
			<p class="mb-50 font-weight-bold">Reasons for not being able to get actual quotes</p>
			<p class="p-75 border rounded" style="background-color: #f9f9f9">{{ currentOrder.reasons }}</p>
		</div>
		<p>List of collected Quotes</p>
		<base-table :headers="headers" :items="currentOrder.items" table-top paginate class="shadow-none border-secondary"></base-table>
	</div>
</template>

<script>
import BaseTable from '@/components/BaseTable.vue';

export default {
	name: 'OrderDetails',

	components: { BaseTable },

	props: {
		currentOrder: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		headers: [
			{ key: 'item_id', label: 'ID', sortable: true },
			{ key: 'item', sortable: true },
			{ key: 'quantity', sortable: true },
			{ key: 'price', sortable: true },
			{ key: 'total', sortable: true },
		],
	}),
};
</script>

<style lang="scss" scoped></style>
