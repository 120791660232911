<template>
	<div>
		<b-tabs vertical content-class="col-12 col-md-10 mt-1 mt-md-0" pills nav-wrapper-class="col-md-2 col-12" nav-class="nav-left" lazy>
			<b-tab title="Orders">
				<Orders />
			</b-tab>
			<b-tab title="Accepted Orders">
				<AcceptedOrders />
			</b-tab>
			<b-tab title="Invoices">
				<Invoices />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import Orders from './orders/Index.vue';
import AcceptedOrders from './accepted-orders/Index.vue';
import Invoices from './invoices/Index.vue';

export default {
	name: 'Purchases',

	components: { BTabs, BTab, Orders, AcceptedOrders, Invoices }
};
</script>

<style lang="scss" scoped></style>
