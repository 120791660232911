<template>
	<div>
		<base-table :items="orders" :headers="ordersHeaders" table-top paginate>
			<template #cell(actions)="data">
				<b-button variant="outline-secondary" class="cursor-pointer" size="sm" @click="openDetails(data.item)">
					<span class="align-middle">View </span>
				</b-button>
				<b-button
					v-if="!data.item.accepted"
					v-b-modal.receive-order
					variant="outline-secondary"
					class="cursor-pointer ml-50"
					size="sm"
					@click="acceptOrder(data.item)"
				>
					<span class="align-middle">Accept </span>
				</b-button>
			</template>

			<template #cell(type)="{ value }">
				<span>{{ value === 'نعم' ? 'عروض اسعار فعلية' : 'عروض اسعار شفوية' }}</span>
			</template>
		</base-table>

		<b-modal v-model="showDetails" title="Order details" size="lg" hide-footer centered>
			<Details :current-order="currentOrder" />
		</b-modal>
	</div>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import store from '@/store';
import api from '@/utils/api';
import Details from './Details.vue';

export default {
	name: 'Purchases',

	components: { BaseTable, BModal, BButton, Details },

	data: () => ({
		orders: [],

		ordersHeaders: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'date', sortable: true },
			{ key: 'place', sortable: true },
			{ key: 'type', sortable: true },
			{ key: 'chosen_suppler', sortable: true },
			{ key: 'closing_hour', sortable: true },
			{ key: 'actions' }
		],

		showDetails: false,
		currentOrder: null
	}),

	async created() {
		this.orders = await store.dispatch('purchases/getOrders', this.$route.params.subId);
	},

	methods: {
		openDetails(order) {
			this.currentOrder = order;
			this.showDetails = true;
		},

		async acceptOrder(order) {
			const data = {
				sub_project_id: this.$route.params.subId,
				submission_id: order.id,
				place: order.place,
				closing_hour: order.closing_hour,
				date: order.date,
				reasons: order.reasons,
				suppler: order.chosen_suppler,
				type: order.type === 'نعم' ? 1 : 0,
				items: order.items.map((i) => ({
					sub_item_id: i.item_id,
					quantity: i.quantity,
					price: i.price
				}))
			};

			await api.call({ path: `purchases/${this.$route.params.id}/orders`, data });
		}
	}
};
</script>

<style lang="scss" scoped></style>
